// src/Main.js
import React from 'react';
import logo from './assets/images/wikimint-developer-logo.webp'; // Adjust the path if necessary

const Main = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img src={logo} alt="Logo" style={{ width: '150px', height: 'auto' }} /> {/* Adjust size as needed */}
      <h1 class="my-3">Welcome to  Wikimint</h1>
      <h2 class="display-1 text-secondary mb-4">App</h2>
      <p class="small">&copy; <a href="https://developer.wikimint.com">Wikimint Developer</a></p>
    </div>
  );
};

export default Main;
