// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main'; // Import the Main component
import NotFound from './NotFound'; // Import the NotFound component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Include Bootstrap's JS

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Display the Main component at the root route */}
        <Route path="/" element={<Main />} />

        {/* Catch all unmatched routes and display the NotFound component */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
